import { render, staticRenderFns } from "./AuthAboutus.vue?vue&type=template&id=4d9a6ea7&scoped=true&"
import script from "./AuthAboutus.vue?vue&type=script&lang=js&"
export * from "./AuthAboutus.vue?vue&type=script&lang=js&"
import style0 from "./AuthAboutus.vue?vue&type=style&index=0&id=4d9a6ea7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d9a6ea7",
  null
  
)

export default component.exports