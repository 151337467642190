<template>
<div>

    <div class="container con guide" style="max-width: 856px;padding: 0;" :class="{'rtl': lang ==1, 'ltr': lang==2 }">
      
    <div class="card shadow-sm rounded my-3 ">
      <div class="w-100">
        <!-- <h2 >{{ $t("meter_screens_guide") }}</h2> -->
        <p class="mb-4"
         style="color: var(--secondary-color);
                font-size: 16px;
                font-weight: 500 !important;">
        {{$t("meter_type")}} : {{meter_vendor}}</p>
        
      </div>
    
      <Loading v-if="loading" />
     <ElectricMeter :company='company' v-if="meter_vendor=='ADD'"/>
     <WaterMeter v-else-if="meter_vendor=='HEXCELL'"/>
     <InhemeterElectricMeter v-else-if="meter_vendor=='Inhemeter'"/>
     <p v-else style="text-align: center; font-size: 24px;font-weight: 600 !important; color: #002369;">{{$t("no_data_found")}}</p>
     <!-- <GasMeter v-else-if /> -->
    </div>
  </div>

</div>

</template>
<script>

import Loading from "@/components/Loading.vue";
import WaterMeter from "@/components/Guide/WaterMeter.vue";
import GasMeter from "@/components/Guide/GasMeter.vue";
import ElectricMeter from "@/components/Guide/ElectricMeter.vue";
import InhemeterElectricMeter from "@/components/Guide/InhemeterElectricMeter.vue";
import meterServices from "../services/meters";

export default {
   components: {
    WaterMeter,
    GasMeter,
    ElectricMeter,
    InhemeterElectricMeter,
    Loading
 
  },
  props:["tab","lang",'company'],
  data() {
    return {
     
      meter_type:null,
      meter_vendor:'',
      loading:true,
       tabsData: [],
        selectedTab: "",
        selectedMeter: {}
  
  }
  },
  watch:{
   'tab'(){
     this.injectTabData();  
   }
  
  },
  methods: {
    injectTabData(){
      this.tabsData=this.tab.tabsData;
        this.selectedTab=this.tab.selectedTab;
        this.selectedMeter=this.tab.selectedMeter;
        const temp = this.tabsData.filter(meter => meter.code? meter.code == this.selectedTab:meter.account_reference==this.selectedTab)
        this.meter_type=temp[0].meter_type;
        this.getMeterVendor();
    },
    onChange(e){
       this.meter_type=e;
    },
    getMeterVendor(){
      this.loading=true;
      meterServices.getSingleMeter(this.selectedMeter)
      .then((data)=>{
        this.meter_vendor=data.mdm_meters_manufacturers;
      
      }).then(()=>{
        this.loading=false;
      })
    }
  },
  created(){
    if(this.tab !=0){
      this.injectTabData(); 
    }
        
  }
};
</script>
<style scoped>

@media (max-width:760px) {
  .card{
  padding: 30px 10px !important;
 
}
}
@media (max-width:860px) {
  .guide{
    padding:0px 20px !important;
  }
}

</style>