<template >
        <div style="margin: 24px 20px;" :class="{'rtl': lang==1, 'ltr': lang==2}">
            <p class="about-header">{{$t("about_us")}}</p>
             <div class='img-container'>
                <img  :src="require(`../assets/${company.about_img_src}`)" alt="about us">  
             <p>
             {{lang==1?company.about_paragrh.ar:company.about_paragrh.en}}
             </p>
             </div>
             
        </div>
        

</template>
  
<script>
import Footer from '@/components/Footer.vue';
import LoginNavbar from '@/components/Login/LoginNavbar.vue';
import FaqChild from "@/components/FAQ/FaqChild.vue";
  export default {
    components: {
    Footer,
    LoginNavbar,
    FaqChild
  },

    props: ["lang", "company"],
    
    data() {
      return {
        apply: true,
      }
    },
    created(){
      console.log(this.$route,this.$route.meta)
      
      // this.pageHeader  = this.$t(this.$route.meta.guest);
    },
   watch:{
   },
    methods: {
    
    
    }, 
  };
  </script>
  
  <style scoped>
  .back{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #767676;
  max-width: 856px;
  margin:0px auto;
  margin-top:32px !important;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  /* margin-bottom: 8px; */
}
  .about-header{
    font-weight: 700 !important;
    font-size: 32px;
    line-height: 39px;
    color: #002369;
    margin-top: 48px;
    max-width: 856px;
    margin: 0px auto;
    margin-top: 5px;
    padding: 0px;
    margin-bottom:32px;
  }
    .img-container{
        max-width: 856px;
        margin: auto;
    }
    .img-container img{
        width:100%;
        object-fit: contain;
    }
    .img-container p{
        font-size: 16px;
        font-weight: 500;
        color: #4F4D61;
        margin-top: 16px;
        margin-bottom: 200px;
    } 
  
  @media(max-width:600px){
    .about-header{
    font-size: 24px;
  }
  }

  </style>