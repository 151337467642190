const company_config = {
    // GENERAL INFO
    company_name:"TMG",
    name : {
        arabic: 'مدينتي',
        english: 'Madinaty',
    },
    description: {
        arabic: 'بايلون هي عبارة عن منصة شاملة لإدارة المرافق تعمل على تحويل مرافق المياه و الكهرباء التقليدية في الأسواق الناشئة إلى أسواق فعالة وقابلة للتشغيل البيني و قائمة على البيانات ',
        english: 'Pylon is a comprehensive utility management platform transforming converntional Water and electricity utilities in emerging markets into efficient, interoperable and data driven ones. ',
    },
    logo_path : {
        arabic: 'TMG/logo/footer-logo.png',
        english: 'TMG/logo/footer-logo.png',
        footer_arabic: 'TMG/logo/footer-logo.png',
        footer_english: 'TMG/logo/footer-logo.png',
        dark_logo_ar:'TMG/logo/dark-logo.png',
        dark_logo_en:'TMG/logo/dark-logo.png',
    },
    customer_service_numbers : [
        '01116115445','0221236444'
    ],
    customer_service_emails : [
         'mepcinfo@tmg.com.eg'
    ],
    socialmedia: [
        // {
        //     type: 'facebook',
        //     url: 'https://web.facebook.com',
        //     name: 'TMG_FACEBOOK'
        // },
        // {
        //     type: 'instagram',
        //     url: 'https://www.instagram.com',
        //     name: 'TMG_INSTA'
        // },
        // {
        //     type: 'twitter',
        //     url: 'https://twitter.com',
        //     name: '@TMG_TWITTER'
        // },
        // {
        //     type: 'LinkedIn',
        //     url: 'https://www.linkedin.com',
        //     name: 'TMG LINKEDIND'
        // },
        {
            type: 'email',
            url: 'mepcinfo@tmg.com.eg',
            name: 'mepcinfo@tmg.com.eg'
        },
        
    ],
    showLocationDetails:false,
    locations: {
        ar:['برج زهرة المعادي','66 كورنيش النيل, المعادي, القاهرة' ],
        en:["Zahret El Maadi Tower", "66 Cornish El Nile, Maadi, Cairo"]
    },
    location_map:"Mostakbal City electricity company",
    location_map_link:"https://maps.app.goo.gl/9nJzXWbf8oSsUDHU6",  
    colors: [
        '#e94c28',
        '#002751',
        '#2870b9'
    ],
    PortalLangs:{
        firstLang:'ع',
        secondLang:'En'
    },
    defaultLang:2,
    // LOGIN PAGE
    unAuth_request:false,
    login_faq:false,
    aboutus:false,
    fastRecharge:true,
    fastRecharge_elements:{
        unit_code:false, // changed to account_reference
        account_reference: true,
        meter_code:true,
        phone_number:false,
        amount:true,
        meter_placeholder_ar:"مثال 123456",
        meter_placeholder_en:"e.g. 123456",
        unit_placeholder_ar:"", // changed to account_reference_placeholder_ar
        unit_placeholder_en:"", // changed to account_reference_placeholder_en
        account_reference_placeholder_ar:"مثال EARKGSB13100",
        account_reference_placeholder_en:"e.g. EARKGSB13100",
        phone_number_placeholder_en:"e.g 01234567890",
        phone_number_placeholder_ar:"مثال 01234567890",
    },
    fastRechargeLimit:{
        min:100,
        max:50000,
    },

    first_login_pass_msg_en:"",
    first_login_pass_msg_ar:"",
    
    forgot_pass:true,

    login_footer:true,
    login_footer_elements:{
        numbers:true,
        email:true,
        location:true,
        headQuarters:true,
    },

    
    // NAVBAR
    requests:false,
    faq:true,
    notifications:true,
    raise_complaint_inquiry:true,

    // FOOTER
    mobile_app:false,
    mobile_app_QRs:{
        ios:'https://apps.apple.com/eg/app/midar/id6466739593',
        android:'https://play.google.com/store/apps/details?id=com.pylon.customers.mc',
    },
    footer_back_img_path:'',

    // about us
    about_paragrh:{
        ar:"",
        en:"",   
    },
    about_img_src:'',
    // HOME PAGE

    // meter section 
    remaining_credit:true,
    last_update_date_time:true,
    consumption_chart:true,
    recharge_from_home:true,

    // payment histroy section 
    payment_history_table:true,
    payment_receipt_history_table:true,

    //consumption section
    consumption_history_table:true,
 
    // recharging
    paytabs:false,
    nbe:true,
    response_type:'json',
    enter_total_recahrge_due:false,
    allow_recharge_without_pay_all:false,
    force_pay_all:true,
    show_due_payments:true,
    fees_precentage:0.025,
    fixed_fees:0,
    failure_Recharge_msg_ar:"فشلت بسبب فشل الإتصال بالعداد. الرجاء الاتصال بدعم العملاء على ",
    failure_Recharge_msg_en:"failed due to a failure in communication with the meter. Please contact the customer support on ",
    Invoice_img:'National_Bank_of_Egypt_Logo.png',
    
    // guide page
    show_elec_params_table:false,
    
    // PROFILE

    // personal info
    changePass:true,
    addEmailToContactInfoMsg:false,

    // notification
    show_critical_part:false,
    notfication_settings:false,
    numberOfLoginFooterElements:4,
    notfication_settings_elements:{
     sms:true,
     mobile_app:true,
     customer_portal:true,
     email:true
    },
    
       
}

export default  {company_config}
