<template>
  <div>
    <NavBar  :company="company" :lang="lang"/>
    
    <b-row style="--bs-gutter-x: 0;margin-top:16px">
      <!-- <div v-if="error.status" style="margin-top:24px;" @unmountFeedComponent="error.status=false">
          <Feedback :state="2" :message="error.message" /> 
        </div> -->
      <b-col sm="12" class="p-0">
        <router-link to="/login" class="back2" :class="{'rtl' : lang==1 ,'ltr' : lang==2}">
          <p>
            <i v-if="lang==1" class="pylon-icon-ArrowRight"></i>
            <i v-if="lang==2" class="pylon-icon-Arrow-Left"></i>
            {{$t('back_to_login')}}
          </p>
        </router-link>
        <Invoice :transactionData="transData" :company="company" :lang="lang" />
      </b-col>
    </b-row>

    <Footer :isAuthenticated="true" :company="company" :lang="lang"/>
  </div>
</template>

<script>
import Invoice from "@/components/Recharge/Invoice.vue";
import NavBar from "@/components/Login/LoginNavbar.vue";
import Footer from "@/components/Footer.vue";
import Feedback from'@/components/Feedback.vue';
import rechargeService from "@/services/recharge";

export default {
  components: {
    Invoice,
    NavBar,
    Footer,
    Feedback
  },
  props: ["reachrgeform", "lang", "company"],
  data() {
    return {
      id: null,
      transData: {},
      error:{
        status:false,
        message:"",
      }
    };
  },
  created() {
    this.id = this.$route.params.order_id;

    rechargeService
      .checkPayment({order_id:this.$route.query.order_id},false)
      .then((data) => {
        if(data.result==='FAILURE'){
          this.$router.push({ name: 'Login', query: {order_id:data.id,message:'failure' } });      
        }
        else{
          let date = new Date(data.creationTime).toLocaleString("en");
        this.transData = {
          customer_name: data.customer.name,
          account_reference: data.account_reference,
          id: data.id,
          creationTime: date,
          status: data.result === "SUCCESS" ? this.$t("successfull") : data.result,
          amount: data.amount,
          total_without_tax: data.total_without_tax,
          payment_method: data.payment_operator,
        };
        }
       
      }).catch(err=>{
       this.$router.push({ name: 'Login', query: {message:err.message } });
      });
  },
};
</script>

<style scoped>
.back2 {
  margin-top: 24px;
    font-weight: 600!important;
    color: var(--secondary-color);
    text-decoration: none;
    max-width: 200px;
    display: block;
    margin-left: 32px;
    margin-right:32px;
}
.back2.rtl {
   margin-right: 32px;
   margin-left: calc(100% - 230px);  
}
.back2 p{
  margin:0px;
}
</style>
