const company_config = {
     // GENERAL INFO
     company_name:"HALLAM",
    name : {
        arabic: 'شبكات',
        english: 'Shabakat',
    },
    description: {
        arabic: 'بايلون هي عبارة عن منصة شاملة لإدارة المرافق تعمل على تحويل مرافق المياه و الكهرباء التقليدية في الأسواق الناشئة إلى أسواق فعالة وقابلة للتشغيل البيني و قائمة على البيانات ',
        english: 'Pylon is a comprehensive utility management platform transforming converntional Water and electricity utilities in emerging markets into efficient, interoperable and data driven ones. ',
    },
    logo_path : {
        arabic: 'HALLAM/logo/logo-english.png',
        english: 'HALLAM/logo/logo-english.png',
        footer_arabic: 'HALLAM/logo/logo-english.png',
        footer_english: 'HALLAM/logo/logo-english.png',
        dark_logo_ar:'HALLAM/logo/dark-logo.png',
        dark_logo_en:'HALLAM/logo/dark-logo.png',
    },
    customer_service_numbers : [
        '01287533361',
        '01287533316',
    ],
   
    customer_service_emails : [
        'customerservice@shabakat.com.eg',
    ],
    socialmedia: [
        // {
        //     type: 'facebook',
        //     url: 'https://web.facebook.com/mostakbalcityofficial/',
        //     name: 'Mostakbalcityofficial'
        // },
        // {
        //     type: 'instagram',
        //     url: 'https://www.instagram.com/mostakbal_city_official/',
        //     name: 'Mostakbalcityofficial'
        // },
        // {
        //     type: 'twitter',
        //     url: 'https://twitter.com/Mostakbal_city',
        //     name: '@Mostakbal_City'
        // },
        // {
        //     type: 'LinkedIn',
        //     url: 'https://www.linkedin.com/company/mostakbalcityofficial/',
        //     name: 'Mostakbal City Official'
        // },
        {
            type: 'email',
            url: 'customerservice@shabakat.com.eg',
            name: 'customerservice@shabakat.com.eg'
        },
        
    ],
    showLocationDetails:false,
    locations: {ar:[],en:[]},
    location_map:"",
    location_map_link:"",
    colors: [
        '#E3655B',
        '#145190',
        '#2870b9'
    ],
    PortalLangs:{
        firstLang:'ع',
        secondLang:'En'
    },
    defaultLang:2,
    // LOGIN PAGE
    unAuth_request:false,
    login_faq:false,
    aboutus:false,
    fastRecharge:false,
    fastRecharge_elements:{
        unit_code:false,
        meter_code:false,
        phone_number:false,
        amount:false,
        meter_placeholder_ar:"",
        meter_placeholder_en:"",
        unit_placeholder_ar:"",
        unit_placeholder_en:"",
        phone_number_placeholder_en:"",
        phone_number_placeholder_ar:"",
    },
    fastRechargeLimit:{
        min:100,
        max:50000,
    },

    first_login_pass_msg_en:"",
    first_login_pass_msg_ar:"",
   
    forgot_pass:false,
  
    login_footer:true,
    numberOfLoginFooterElements:2,
    login_footer_elements:{
        numbers:true,
        email:true,
        location:false,
        headQuarters:false,
    },

    // NAVBAR
    requests:false,
    faq:true,
    notifications:true,
    raise_complaint_inquiry:true,

    // FOOTER
    mobile_app:true,
    mobile_app_QRs:{
        ios:'https://apps.apple.com/eg/app/shabakat/id1572695829',
        android:'https://play.google.com/store/apps/details?id=com.elsewedyintel.customers',
       },
    footer_back_img_path:'',
   
    // about us
    about_paragrh:{
        ar:"",
        en:"",   
    },
    about_img_src:'',
    // HOME PAGE

    // meter section
    remaining_credit:true,
    last_update_date_time:true,
    recharge_from_home:true,
    consumption_chart:true, 

    // payment histroy section 
    payment_history_table:true,
    payment_receipt_history_table:true,

    //consumption section
    consumption_history_table:true,

    // recharging
    paytabs:true,
    nbe:false,
    response_type:"html",
    enter_total_recahrge_due:true,
    allow_recharge_without_pay_all:false,
    force_pay_all:true, 
    show_due_payments:true,
    fees_precentage: 0.01,
    fixed_fees:1,
    failure_Recharge_msg_ar:" بسبب فشل الإتصال بالعداد. الرجاء الاتصال بدعم العملاء على ",
    failure_Recharge_msg_en:"faild due to a failure in communication with the meter. Please contact the customer support on ", 
    Invoice_img:'PayTabs-Logos_English.webp',
    
     // guide page
     show_elec_params_table:false,
    // PROFILE

    // personal info
    changePass:true,
    addEmailToContactInfoMsg:false,

    // notification  
    show_critical_part:false,
    notfication_settings:false,
    notfication_settings_elements:{
        sms:true,
        mobile_app:true,
        customer_portal:true,
        email:true
       },

       
}

export default  {company_config}
