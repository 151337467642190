<template>
  <div :class="{'nav-font-size': lang == 2 }">
 <b-navbar type="dark" class="navBig login-nav w-100" :class="{ 'rtl': lang ==1, 'ltr': lang == 2 }" >
       <img v-if="lang == 2" class="logo col-sm-4 col-xs-6 " :src="require(`@/Projects/${company.logo_path.english}`)"/>
        <img v-if="lang == 1" class="logo col-sm-4 col-xs-6 " :src="require(`@/Projects/${company.logo_path.arabic}`)" />
    
    
       <b-navbar-nav v-if="isAuthenticated!==true"  class="d-flex justify-content-end nav-ele"  :class="{ 'rtl': lang ==1, 'ltr': lang ==2 }">
            <b-nav-item v-if="company.unAuth_request && !isMobile" to="unauth-request">
              {{ $t("requests") }}
            </b-nav-item>
            <b-nav-item v-if="company.login_faq && !isMobile" to="FAQLOGIN">
              {{ $t("faq") }}
            </b-nav-item>
            <b-nav-item v-if="company.aboutus && !isMobile" to="aboutus">
              {{ $t("about_us") }}
            </b-nav-item>
              
            <div v-if="!isMobile" class='verttical-separator' style="margin: 0px 8px;"></div>
            <b-button @click="changeLang()" id="lang-button" class='lang-btn'  style="margin: 0px 8px;">
                    <i :class="{'pylon-icon-Lang rtl': lang ==1, 'pylon-icon-Lang': lang==2 }"></i>
                    <span  v-if="lang==2">{{company.PortalLangs.secondLang}}</span>
                    <span style=" margin-top: -8px;"  v-if="lang==1">{{company.PortalLangs.firstLang}}</span>
            </b-button >

       </b-navbar-nav>
        
  </b-navbar>
  </div>
</template>
<script>
import langServices from '@/services/lang';

export default {

  props: {
    company: {
      default: () => {},
    },
    isAuthenticated:{
      default: () => {},
    },
    lang:{
      default: () => {},
    },
  
    
  },
  data(){return{
    isMobile:false,
    screenWidth:0,
  }},
   created() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
    this.screenWidth = window.innerWidth;
  })
},
watch: {
 screenWidth() {
    this.isMobile= this.screenWidth <= 767?true:false;
      this.$emit('isMobile', this.isMobile);
  },
},
  methods: {
    changeLang() {
         let langOption=this.lang==1?2:1;
      langServices.updateLangCookie(langOption)
      .then(() => this.$router.go())
    },
  },
};
</script>

<style scoped>
.login-nav.navbar-dark .navbar-nav .nav-link.router-link-exact-active:after{
 display: none !important;

}
.navBig.navbar-dark .navbar-nav .nav-link,.navbar-nav .nav-link:hover{
  padding:0px;
  margin:0px 8px;
}
.navbar-expand {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
   
}
.navBig {
    background-color: var(--secondary-color);
    color: var(--inverse-text-color);
    padding: 10px;
    min-height: 14vh;
}
.navbar-brand {
  margin-right: 0px !important;
}
.logo{
    margin: 0px 38px;
    margin-top: 4px;
    width: 143px;
    height: 64px;
    -o-object-fit: contain;
       object-fit: contain;
}
.navBig.ltr .navBigFonts{
  margin-left: 35%;
}
.navBig.rtl .navBigFonts{
  margin-right: 25%;
}
.body:lang(1) {
  direction: rtl;
}

.navBig.login-nav .btn{
  padding:0px !important;
}
.nav-font-size{
  font-size: 15px;
}
.nav-ele{
  padding: 0;
  display: contents;
  align-items:center;
  min-width:200px;
  margin:16px 30px;
  gap:8px;
}
 @media (max-width:767px) {
    .logo, .nav-ele{
      margin:0px 12px;
    }
    .nav-ele{
      margin:16px 12px;
    }
 }

</style>