export default {
// LOGIN PAGE
  login: 'Login',
  username: 'Client Code',
  username_placeholder: 'eg xxxxx',
  email_placeholder: 'example@example.com',
  password: 'Password',
  new_password: 'New Password',
  account_reference:"Client Code",
  password_placeholder: '*******',
  first_time_pass:" If you’re loging in for the first time, your password will be your ",
  submit: 'Login',
  login_error_feedback: 'Please Check Client code & password and try again',
  customer_service_numbers: 'Customer service numbers',
  customer_support: 'Customer Support',
  powered_by:"Powered by",
  about_us:'About Us',
  // ===================================================================================================================================
  // =========================================== Validation localisation ===============================================================
  // ===================================================================================================================================
  invalida_email: {
    required: 'Email address is required',
    format: "Must be a valid email"
  },

  // ===================================================================================================================================
  // =========================================== Validation localisation ===============================================================
  // ===================================================================================================================================
  Apply_for_meter:"Apply For New Meter",
  recharge_meter:"Meter Recharge",
  recharge_meter_and_due:"Meter Recharge & Pay Due Payments",
  or:"OR",
  meter_code:"Meter Number",
  meter_code_placeholder:"e.g. xxxxxxxx",
  phone_number:"Phone Number",
  phone_num_placeholder:"e.g. 01xxxxxxxxx",
  unit_code:"Unit Code:",
  unit_code_placeholder:"e.g. xx",
  recharge_value:"Recharge Value",
  recharge_value_placeholder:"e.g. 100",
  total_after_tax: "Total amount Including Fees",
  We_accept:"We accept",
  Recharge:"Recharge",
  back:"Back",
  reset_password:"Reset Password",
  phone_num:"Phone Number",
  phone_number_placeholder:" Enter your phone number",
  via_sms:"Reset Via SMS",
  via_email:"Reset Via Email",
  forgot_pass:"Forgot Password?",
  forget_msg:"Contact our customer support To Reset Password on",
  sms_note:"An SMS has been sent to you with a temporary password. Use this password to login",
  email_note:"An Email has been sent to you with a temporary password for you to login.",
  didnt_recieve_email:"Didn’t recieve Email?",
  didnt_recieve_sms:"Didn’t recieve SMS?",
  resend_again:"Resend Again",
  after:"after 01:29",
  go_to_login:"Go To Login Page",
  back_to_login:"Back to login",
  home: 'Home',
  meter:"Meter",
  my_meters:"My Meters",
  alarm_services: 'Alarm Services',
  meters_screens_guide: 'Meters Screens Guide',
  meter_screens_guide:"Meter Screens Guide",
  requests:"Requests",
  submit_request: 'Submit Request',
  faq:"FAQ",
  faq_header:"FAQs",
  customer_complaint: 'Customer Complaint',
  water: 'Water',
  electricity: 'Electricity',
  gas: 'Gas',
  meter_no: 'Meter No',
  charge_amount: 'Charge Amount',
  payment_methods: 'Payment Methods',
  last_recharge_date: 'Last Recharge Date: ',
  last_payment_date: "Last Payment Date: ",
  thursday: 'Thursday',
  date_of_meter_reading: 'Date Of Meter Reading: ',
  remaining_credit: 'Remaining Credit',
  required_money: 'Required Amount',
  traiff_step:'Traiff step',
  recharge: 'Recharge Balance / Due Payments',
  reflected_soon:'your last charge (120 EGP) will be reflected within 2 hours',
  last_recharge_msg:"Your last recharge",
  failure_Recharge_msg:"faild due to a failure in communication with the meter. Please contact the customer support on ", 
  pay_bills: 'Pay Bills',
  current_month: 'Current Month',
  as_of:"Last updated on",
  last_month: 'Current Month',
  cons:"Consumption",
  val_of_cons:"Value Of Consumption",
  curr_cons:'Current consumption',
  curr_val_of_cons:'Current Value Of Consumption',
  Money_Spent_on_consumption:"Your consumption",
  chart_Alignment:"left",
  The_red_section:"The red section",
  is_a_prediction_of_what_you_will_consume_and_pay:" is a prediction of what will you will consume and pay",
  at_the_end_of_the_month_if_you_continue:"for the whole month if you continue",
  with_your_current_consumption_pattern :"with current consumption pattern",
  payment_history: 'Payments',
  payment_details_button:"Details",
  payment_details_header:"Payment Details",
  cash_details:"Value",
  clause:"Item",
  consumption: 'Monthly consumption',
  consumption_chart:"Consumption analysis",
  choose_your_request: 'Choose your request',

  pylon: ' Pylon',
  is_a: 'is a',
  comprehensive: 'comprehensive',
  utility_management: 'utility management',
  platform: 'platform',
  transforming: 'transforming',
  conventional: 'conventional',
  and: 'and',
  electricity_utilities: 'electricity utilities',
  in_emerging_markets: 'in emerging markets',
  into_efficient: 'into efficient,',
  interoperable: 'interoperable',
  and: 'and',
  data: 'data',
  driven_ones: 'driven ones.',

  contact_us: 'Contact Us',
  call_us_for_any_inquiry_or_help: 'Call us. For any inquiry or help',
  navigation: 'Site Map',
  location:"Location",
  project_location:"Project Location",
  headquarters: 'Headquarters',
  Profile:"Profile",
  notification:"Notifications",

  download_App:"Download The App",

  enter_your: 'Enter your',
  account_name: 'Username',
  name:"Name",
  account_type: 'Account Type',
  email_address: 'Email Address',
  mobile_number: 'Mobile Number',
  email:"Email",
  building:"Building",
  group:"Group",
  unit:"Unit",
  block:"Block",
  current_password:"Current password",
  new_password_err:"enter the new password",
  new_curr_pass_are_qual:'Current and new passowd are equal',
  confirm_password: 'Re-type New Password',
  update: 'Update',
  update_pesonal_info_note:"If you request to update any data, Please contact the customer support on",
  update_personal_info: 'Update Personal Info',
  update_notifications_settings: 'Update Notifications settings',
  critical_credit:'Critical Credit',
  critical_desc:"It is the credit you want a warning message to be sent when you reach it",
  critical_amount:'Critical Amount',
  critical_amount_desc:"It is the amount you want a warning message to be sent when you reach it",
  complaint_type: 'Complaint Type',
  send: 'Send',

  serial: 'Serial',
  month: 'Month',
  net_consumption: 'Net Consumption',
  estimated_money: 'Total Amount',
  est_money:"Estimated Amount",
  energy_consumption_per_month: 'Energy Consumption / Month',
  estimated_consumption_for_next_month:'Estimated consumption for the next month',
  receipt:"Receipt",
  receipt_no: 'Receipt No',
  show_receipt: 'Show Receipt',
  transaction_date: 'Transaction Date',
  bill_month: 'Bill month',
  from_to: 'From - To',
  status: 'Status',
  payment_date: 'Payment date',
  cash: 'Cash',
  credit_card: 'Credit Card',
  bank_transfer: 'Bank Transfer',
  checks: 'Check',
  on_account: 'Customer Accounts',
  amount: 'Amount to pay',
  Net_Charge:"Net Charge",
  enter_due_and_recharge_amount:"ُEnter reahrge amount & due payments value",

  pay: 'Pay',
  loading: 'Loading',
  sorry: 'Sorry!',
  something_went_wrong: 'Something Went Wrong',
  try_again_later: 'Try again later',
  no_data_found: 'No Data Found',

  card_no: 'Card Number',
  expiry_date: 'Expiry Date',

  // Notations 
  pm: 'PM',
  egp: 'EGP',
  kwh: 'kWh',
  kw: 'KW',
  m3:'cubic metre',
  full_date_notation: '(DD/MM/YYYY HH:MM)',
  date_notation: '(DD/MM/YYYY)',
  month_date_notation: '(MM/YYYY)',

  mdm_billing_cycle: 'Month',
  energy_diff: 'Net Consumption',
  est_tariff_value: 'Total Amount',
  code:'Meter No',

  password_description: "Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters,or emoji.",

  subject: 'Subject',
  description: 'Description',
  choose_meter_no: 'Choose Meter No',
  recharge_balance:"Recharge Balance",
  you_have_dues:"You have",
  due_note:"in due payments to pay in order to be able to recharge your meter",
  you_have_installement:"You have",
  installment_note:"in credit due to financial dues",
  Show_Details:"Show Details",
  Hide_Details:"Hide Details",
  total:"Total",
  credit:"Credit",
  post_due_note:"Below the due payments needs to pay",
  service_payments:"Service Payments",
  due_payments:"Due Payments",
  collection_fees:"Collection Fees",
  select_due:"Select due payments you’d like to pay",
  total_amount:"Total Payment",
  min_charge_amount: 'Minimum recharge amount is',
  min_payment_amount:"Minimum payment amount is",
  min_net_amount:"Minimum net charge amount is",
  min_amount:"Min amount",
  charge_amonut:"Net Charge on Meter",
  enter_amount:"Enter Amount",
  confirmation_message: "Confirming your recharge for meter number:",
  cancel:"Cancel",
  save:"Save",
  make:"Make",
  complaint: 'Complaint',
  inquiry: 'Inquiry',
  personal_info: 'Personal Informations',
  unit_info:'Unit Informations',
  notification_settings: 'Notification Settings',
  sms: 'SMS',
  mobile_app: 'Mobile App',
  customer_portal: 'Customer Portal',
  
  current: 'Current',
  expected: 'Expected',
  current_month: 'Current Month',
  last_month: 'Last Month',

  language: 'Language',

  success: 'Success',
  warning: 'Warning',
  error: 'Error',

  logout: 'Logout',

  // Meter Screen Guide 
  meter_type:"Meter type",
  screen: 'Screen',
  screen_no: 'Screen Number',
  
  desc_0_0_19: 'the remaining credit in the meter (EGP)',
  desc_C_1_0: 'meter number',
  desc_C_1_5: "meter's Application number",
  desc_2_9_0: 'date on meter(DD-MM-YYYY)',
  desc_1_9_0: 'ِtime on meter(HH:MM:SS)',
  desc_0_8_1: 'total consumption (kWh)',
  desc_0_9_1: 'current month consumption (KWh)',
  desc_0_7_1: 'the total amount of energy input to meter (kWh)',
  desc_0_7_3: 'the total amount of energy active on meter (kVA)',
  desc_0_6_15: 'maximum load (KW)',
  desc_F_F_0: 'manipulation codes',
  desc_C_6_18: 'current consumption (EGP)',
  desc_C_14_0: 'active tariff code',
  desc_0_2_0: 'the code of the the currently installed software copy',
  desc_0_7_31: 'momental current value for phase 1 ( A )',
  desc_0_7_51: 'momental current value for phase 2 ( A )',
  desc_0_7_71: 'momental current value for phase 3 ( A )',
  desc_0_7_32: 'momental volt value for phase 1 ( V )',
  desc_0_7_52: 'momental volt value for phase 2 ( V )',
  desc_0_7_72: 'momental volt value for phase 3 ( V )',

  // Water meters
  water_notice_1: "If the card read successfull the led will light once and the LCD shows CD xx.",
  water_notice_2: "If the card read error the led will light twice and the LCD shows Er xx.",
  
  water_desc_1: "This screen shows when the customer puts the card on the meter to update the card's data to charge the card, or to inquiry about the consumption or the credit ( P.s: The number 70 can be 71 in case if the card has a charge to be empty in the meter)",
  water_desc_2: "last 6 digits of the meter's serial",
  water_desc_3: "credit in the meter (EGP)",
  water_desc_4: "total consumption (EGP)",
  water_desc_5: "total consumption (cubic metre)",
  water_desc_6: "current month's consumption (EGP)",
  water_desc_7: "current month's consumption (cubic metre)",

//codes_of_parameters
codes_of_parameters:'Codes of parameters',
card_read_code:"Card read code",
card_read_code_description:"Card read code description",
error_code:"Error code",
error_code_description:"Error code description",

code_desc_1:"The initialized card is processed",
code_desc_2:"Adjust time card processing is completed",
code_desc_3:"Set card processing is completed",
code_desc_4:"Query card (query function) processing is completed",
code_desc_5:"Query card (change table function) processing is completed",
code_desc_6:"Add water card processing is completed",
code_desc_7:"Invalid user card processing is completed",
code_desc_8:"Effective user card (water purchase) processing is completed",
code_desc_9:"User card (water return) processing is completed",
code_desc_10:"Prepaid/Post mode setting card processing is completed",

error_desc_1:"Read card error",
error_desc_2:"Wrong edition",
error_desc_3:"Error checking passward",
error_desc_4:"Illegal card (card identification error)",
error_desc_5:"System code error/back write card error",
error_desc_6:"Add water card amount error",
error_desc_7:"System code error (user card)",
error_desc_8:"Non water meter number (user card)",
error_desc_9:"Don't mistake (user card) card",
error_desc_10:" ",

// inhemeter desc

  inhemeter_desc_0_9_1:"Real-time",
  inhemeter_desc_0_9_2:"Real-date",
  inhemeter_desc_1_6_0:"Positive active maximum demand and occurrence time (A+)",
  inhemeter_desc_1_6_0_1:"Last month forward active maximum demand and occurrence time",
  inhemeter_desc_1_6_0_2:"Last 2nd month forward active maximum demand and occurrence time",
  inhemeter_desc_1_6_0_3:"Last 3rd month forward active maximum demand and occurrence time",
  inhemeter_desc_1_8_1:"Positive active energy (A+) total in traiff 1",
  inhemeter_desc_3_8_1:"Positive reactive energy (Q+) total in traiff 1",
  inhemeter_desc_2_8_1:"Negative active energy (A-) total in traiff 1",
  inhemeter_desc_4_8_1:"Negative reactive energy (Q-) total in traiff 1",
  inhemeter_desc_1_8_2:"Positive active energy (A+) total in traiff 2",
  inhemeter_desc_3_8_2:"Positive reactive energy (Q+) total in traiff 2",
  inhemeter_desc_2_8_2:"Negative active energy (A-) total in traiff 2",
  inhemeter_desc_4_8_2:"Negative reactive energy (Q-) total in traiff 2",
  inhemeter_desc_15_6_1:"Active maximum demand and occurance time (A) in traiff 1",
  inhemeter_desc_15_6_2:"Active maximum demand and occurance time (A) in traiff 2",
  inhemeter_desc_15_8_0:"Active energy (|+A|+|-A|) total",
  inhemeter_desc_15_8_1:"Active energy (|+A|+|-A|) total in traiff 1",
  inhemeter_desc_15_8_2:"Active energy (|+A|+|-A|) total in traiff 2",
  inhemeter_desc_1_8_0:"Active energy import (+A)",
  inhemeter_desc_2_8_0:"Negative active energy (A-) total",
  inhemeter_desc_3_8_0:"Positive reactive energy (Q+) total",
  inhemeter_desc_4_8_0:"Negative reactive energy (Q-) total",
  inhemeter_desc_42_0_0:"COSEM logical device name",
  inhemeter_desc_C_53_12:"Cumulative tripping times caused by reverse phase sequence current",
  inhemeter_desc_81_7_4:"Phase Angle from I(L1) to U(L1)",
  inhemeter_desc_81_7_15:"Phase Angle from I(L2) to U(L2)",
  inhemeter_desc_81_7_26:"Phase Angle from I(L3) to U(L3)",
  inhemeter_desc_13_7_0:"Instantaneous power factor (+A/+VA)",
  inhemeter_desc_31_7_0:"Instantaneous current L1(A)",
  inhemeter_desc_51_7_0:"Instantaneous current L2(A)",
  inhemeter_desc_71_7_0:"Instantaneous current L3(A)",
  inhemeter_desc_32_7_0:"Instantaneous current L1(V)",
  inhemeter_desc_52_7_0:"Instantaneous current L2(V)",
  inhemeter_desc_72_7_0:"Instantaneous current L2(V)",
  inhemeter_desc_33_7_0:"Instantaneous power factor (PF) L1",
  inhemeter_desc_53_7_0:"Instantaneous power factor (PF) L2",
  inhemeter_desc_73_7_0:"Instantaneous power factor (PF) L3",
  inhemeter_desc_0_4_2:"CT Ratio(num)",
  inhemeter_desc_0_4_5:"CT Ratio(den)",


  // POSTPAID required bills
  payment_details:"details",
  TName: 'Category',
  Type: 'Type',
  Date: 'Date',
  Name: 'Name',
  Value: 'Value',


// request page
request_steps:"Steps to complete the application",
needed_docs:"Needed Documents",
paper_1:"Download the application form and print it, and complete the required data",
paper_2:"National ID",
paper_3:"paper ...",
paper_4:"paper .....",
step_1:"Fill the application form you printed and sign it.",
step_2:"Go to administration center and hand them all the needed documents",
Download:"Download",  
Upload:"Upload",
confirm:"Confirm",
notes_placeholder:"Write your notes",
// خطوات طلب التنازل
step11:"Download the request",
step12:"Print it out",
step13:"Fill and sign it",
step14:"Upload the request",
step15:"Write Your Notes",
step16:"Confirm the request",

// Invoice 
payment_voucher: "Payment Voucher",
payment_receipt: "Payment Receipt",

hello: "Hello, ",
your_transaction: "Your transaction",
number: "number",
was: "was",
successfull: "Successful",
transaction: "Transaction",
amount_payed: "Amount Payed",
service: "Service",
details: "Details",
service_details: "Service Details",
card: "Card",
customer_code: "Customer Code",
charge_token: "Charge Token",
mostakbal_city: "Mostakbal City",
service_value: "Service Value",
the_price: "The Price",
the_tax: "The Tax",
id: "ID",
order_summary: "Order summary",
payment_method: 'Payment Method',
tax_on: "Tax on",
invoice_additional_message: "For more information regarding service value, contact your service provider and use transaction reference",
invoice_failure_additional_message:"For more information regarding faliure reason, contact your service provider and use transaction reference",
invoice_powered_by: "Powered By",
failed:"failed",

// Requests 
request_feedback: {
  your_request: "Your request number",
  successfull: 'has been submitted successfully',
  failed: 'has failed unfortunately',
  check: "Note that the application will not be processed until the application form and required documents are completed and then sent to the customer service email",
  another_request: "Want to make another request?",
},

// Fast Recharge
fast_recharge: {
  shortAmount: 'Sorry, It appears that you have due payments, so the minimum recharge amount is (100 + your due payments amount) EGP'
},
invalid_recharge_request:"recharge request is invalid",
canceled_recharge_request:"recharge request is canceled",
invalid_pay_bills_request:"payment request is invalid",
canceled_pay_bills_request:"payment request is canceled",


// search
search_placeholder:"Search Keywords...",
search:"Search",

max_recharge_amount:"Maximum recharge amount is",
max_payment_amount:"Maximum payment amount is",

change_password:"Change Password",
success_password_msg:'Password has been reset successfully!',

submit_request_for_registered_user_note_1:"Note: If you are a registered user please",
submit_request_for_registered_user_note_2:"first in order to be able to complete and submit the request online.",
no_docs_required:"No documents required",
copy_rights:"Pylon © 2024. All Rights Reserved.",
enter_amount_for_payment:"please enter amount",
critical_credit_note:"Make sure you critical credit is not empty and it is a number greater than or equal 100",
mobile_validation:[ "Make sure Your Mobile is :",
"Not empty","Only contains numbers"," Contains exactly 11 digits"
],
enter_current_pass:"Enter the current password",
email_validation:[" Make sure Your email is :","Not empty","In the format 'something@example'"],
password_validation:["Between 8 and 20 characters long","At least one lower case Letter",
"At least one Upper case Letter","At least one Number","At least one special character (e.g #,%,$)"],
no_matching_pass:" Doesn't match, make sure you write the same password",
supplied_data_wrong:"supplied data are wrong",
required_meter:" Meter Code is required",
subject_required:"Subject is required",
desc_required:"Description is required",

your_inquiry:"Your inquiry",
your_complaint:"Your complaint",
registered_success:"has been registered successfully",
case_number:"Your case number is :",
non_refelected_recharge_1:"The last recharge ",
non_refelected_recharge_2:"will be reflected Within 2 hours",

no_complaints_found:"No complaints found",
no_inquiries_found:"No inquiries found",

should_Add_due_payment_value:"you should add the due payments value : ",
faild_login_msg:"something went wrong when trying to login",

zero:"0",
};

