import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Profile from '../views/Profile.vue';
import Request from '../views/Request.vue';
import Guide from '../views/Guide.vue';
import FAQ from '../views/FAQ.vue';
import FAQLOGIN from '../views/FAQLOGIN.vue';
import ForgotPass from '../views/ForgotPass.vue';
import Payment from '../views/Payment.vue';
import PaymentReciept from '../views/PaymentReciept.vue';
import unAuthRequest  from '../views/unAuthRequest.vue';
import AboutUS  from '../views/AboutUS.vue';
import AuthAboutus  from '../views/AuthAboutus.vue';
import VueApexCharts from 'vue-apexcharts';
import { getCookie } from "../utils";

Vue.use(VueApexCharts);
Vue.use(Router);
Vue.component('apexchart', VueApexCharts);

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  routes: [
    {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    meta: {
      guest: true,
      containsMeterNav:false,
      pagetTitle:'',
    },
  }
    ,
    {
      path: '/paymentReceipt',
      name: 'PaymentReceipt',
      component: PaymentReciept,
      meta: {
        requiresAuth: true,
        containsMeterNav:false,
        pagetTitle:'',
      },
    }
      ,
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        guest: true,
        containsMeterNav:false,
        pagetTitle:'',
      },
    },
    {
      path: '/forgot-pass',
      name: 'ForgotPass',
      component: ForgotPass,
      meta: {
        guest: true,
        containsMeterNav:false,
        pagetTitle:'',
      },
    },
    {
      path: '/unauth-request',
      name: 'unAuthRequest',
      component: unAuthRequest,
      meta: {
        guest: true,
        containsMeterNav:false,
        pagetTitle:'',
      },
    },
    {
      path: '/aboutus',
      name: 'AboutUS',
      component: AboutUS,
      meta: {
        guest: true,
        containsMeterNav:false,
        pagetTitle:'',
      },
    },
    {
      path: '/AuthAboutus',
      name: 'AuthAboutUS',
      component: AuthAboutus,
      meta: {
        requiresAuth: true,
        containsMeterNav:false,
        pagetTitle:'about_us',
      },
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: true,
        containsMeterNav:true,
        pagetTitle:'home',
      },
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        requiresAuth: true,
        containsMeterNav:true,
        pagetTitle:'Profile',
      },
    },
    {
      path: '/guide',
      name: 'Guide',
      component: Guide,
      meta: {
        requiresAuth: true,
        containsMeterNav:true,
        pagetTitle:'meters_screens_guide',
      },
    },
    {
      path: '/request',
      name: 'Request',
      component: Request,
      meta: {
        requiresAuth: true,
        containsMeterNav:true,
        pagetTitle:'requests',
      },
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ,
      meta: {
        requiresAuth: true,
        containsMeterNav:false,
        pagetTitle:'',
      },
    },
    {
      path: '/faqlogin',
      name: 'FAQLOGIN',
      component: FAQLOGIN,
      meta: {
        guest: true,
        containsMeterNav:false,
        pagetTitle:'',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
   if (to.matched.some((record) => record.meta.requiresAuth)) {
    if ( getCookie('user_id') == null ) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if ( getCookie('user_id')==null ) {
      next();
    } else {
      next({ name: 'Home' });
    }
  } else {
    next();
  }
});

export default router;
