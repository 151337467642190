F<template>
  <div id="footer" :class="{'rtl': lang ==1, 'ltr': lang ==2 }">

    <!-- <div class="container-fluid p-2 " style="display: flex;flex-direction: row;justify-content: space-between; width: 100%; " :class="{'rtl': lang === 'ar', 'ltr': lang === 'en' }"> -->
      <div class="info-div"> 

        <span class="logo-col">
            <img v-if="lang ==2" class="logo logoo" :src="require(`@/Projects/${company.logo_path.footer_english}`)"/>
            <img v-if="lang ==1" class="logo logoo" :src="require(`@/Projects/${company.logo_path.footer_arabic}`)" />
        </span>

        <span v-if="company.showLocationDetails" class="col1" >
            <h4 class="lines">{{ $t("location") }}</h4>
            <span class="sub-header">{{$t('headquarters')}}</span>
            <p v-if="lang==1"> {{company.locations.ar.join(',')}}</p>
            <p v-if="lang==2"> {{company.locations.en.join(',')}}</p>

            <div class="d-flex align-items-center" style="column-gap: 8px;">
              <i class="pylon-icon-Location"></i>
              <a :href="`${company.location_map_link}`" target="_blank" class="sub-header text-decoration-underline">{{$t('project_location')}}</a>
            </div>

        </span>

        <span  class="col2"  >
          <h4 class="lines">{{ $t("contact_us") }}</h4>
          <p>{{ $t("call_us_for_any_inquiry_or_help") }}</p>

          <div>
            <!-- CUSTOMER SERVICE NUMBERS -->
            <div v-for="number in company.customer_service_numbers" :key="number" class="phone-info">
                <i  :class="{'pylon-icon-Phone-Arabic': lang==1 , 'pylon-icon-Phone-English' : lang==2}"></i>
                <a :href="`tel:${number}`"><span class="mx-2 ">{{number}}</span></a>
            </div>
         
            <!-- SOCIAL MEDIA LINKS -->
            <div v-for="sm in company.socialmedia" :key="sm.url" class="social-info">             
              <a @click="sendMail(sm.url)" v-bind:href="outputUrl" v-if="sm.type=='email'" >
                <i class="pylon-icon-Email" > </i>
                <span class="mx-2">{{sm.name}}</span>
              </a>
            
              <a :href="sm.url" target="_blank" v-else>
                <i :class="{'pylon-icon-Facebook': sm.type === 'facebook', 
                'pylon-icon-Instegram': sm.type==='instagram', 
                'pylon-icon-Twitter': sm.type === 'twitter', 
                'pylon-icon-Linkedin': sm.type==='LinkedIn',
               'pylon-icon-Youtube': sm.type==='youtube',
                }">
                </i>
                <span class="mx-2">{{sm.name}}</span>
              </a>
            </div>
         
          </div>
        </span>

        <span  class="col3"  v-if="isAuthenticated!=true">
          <h4 class="lines">{{ $t("navigation") }}</h4>

          <router-link to="/" class="footer-link">
            <div> <span >{{ $t("home") }}</span></div>
          </router-link>

          <router-link v-if="this.company.requests" to="/request" class="footer-link">
            <div>  <span >{{ $t("requests") }}</span>  </div>
          </router-link>

          <router-link to="/guide" class="footer-link">
            <div >  <span >{{ $t("meters_screens_guide") }}</span> </div>
          </router-link>

          <router-link v-if="this.company.faq" to="/faq" class="footer-link">
            <div >  <span >  {{ $t("faq") }}</span></div>
          </router-link>
         
          <router-link to="/profile" class="footer-link">
            <div> <span >{{ $t("Profile") }}</span> </div>
          </router-link>
             <router-link v-if="this.company.aboutus" to="/AuthAboutus" class="footer-link">
            <div> <span >{{ $t("about_us") }}</span> </div>
          </router-link>

            <!-- <div  v-if="this.company.notifications" style="padding: 4px 8px"> <span >{{ $t("notification") }}</span> </div>  -->
              
        </span>

        <span v-if="company.mobile_app"  class="col4">
          <h4 class="lines">{{ $t("download_App") }}</h4>
         <div style="display: flex; gap: 24px">
            <div>
              <span style="display:block;width:50px;margin-bottom:8px">IOS</span>
              <span class="qr-container">
                 <VueQRCodeComponent 
                  :text= "ios_text"
                  :size="size"
                  color="black"
                  bg-color="#ffff" 
                  error-level="M">
              </VueQRCodeComponent>
              </span>
            </div>
             
            <div>
              <span style="display:block;width:50px;margin-bottom:8px">Android</span>
              <span class="qr-container">
              <VueQRCodeComponent 
              :text= "android_text"
                 :size="size"
                  color="black"
                  bg-color="#ffff" 
                  error-level="M">
              </VueQRCodeComponent>
              </span>
            </div>
                
          </div>

        </span>

      </div>
   
    <!-- </div> -->
<div v-if="company.footer_back_img_path != ''">
<img class="footer-back-img" :src="require(`@/Projects/${company.footer_back_img_path}`)"/>
            
</div>
   
    <b-row class="pylon-phrase" v-if="company.description.english.length||company.description.arabic.length" >
          <p v-if="lang==2" class="mt-3">
            {{company.description.english}}
          </p>
          <p v-if="lang==1" class="mt-3">
            {{company.description.arabic}}
          </p> 
    </b-row>
     
    <div class="copyrights ltr">
      <p>{{$t('copy_rights')}}</p>
    </div>

  </div>

</template>
 <script>
import VueQRCodeComponent from 'vue-qrcode-component'
 export default {
   props: ['company',"isAuthenticated","lang"],
   components:{
   VueQRCodeComponent
  },
  data() {
    return {
      outputUrl:'',
      size:80,
      ios_text:"",
     
    }
  },
  created(){
   this.ios_text=this.company.mobile_app_QRs.ios;
   this.android_text=this.company.mobile_app_QRs.android;
  
  },
  methods:{
    sendMail(email){
    this.outputUrl = `mailto:${email}`;
   
}
  }
};
 </script>
 
<style scoped>
.pylon-icon-Youtube:before{
  font-size: 14px;
}
#footer {
  background-color: var(--secondary-color);
  color: var(--inverse-text-color);
}
#footer .info-div{
  padding: 20px 0px;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 24px;
  padding:24px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin: auto;
}
#footer .info-div > span {
  width: 300px;
  max-width: 100%;
}
.logo-col{
  width: 150px !important;
  text-align: center !important;
}


.logoo {
  margin:0px !important;
}
.col1 p{
 font-weight: 400 !important;
 font-size: 16px;
}
.col2 i,.col1 i{
  color: var(--main-color);
}
.col2 .phone-info,.col2 .social-info a{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
i{
  margin:0px 2px;
}
.lines {
  border-bottom: 1px solid var(--main-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 5px 10px 0px;
  font-weight: 400 !important;
}

.sub-header {
  font-weight: 600 !important;
  font-size: 16px;  
  line-height: 20px;
}
.footer-link {
  color: var(--inverse-text-color); 
  text-decoration: none;
}

.footer-link div {
  padding: 4px 8px ;
}

.footer-link:hover  div {
  border-radius: 5px;
  background-color: var(--main-color);
  color: var(--inverse-text-color);
}

a{
  text-decoration: none;
  color:white;
}
a:hover{
  color:var(--hover-color);
}
.qr-container{
  display:block; 
  height: 100px; 
  width: 100px;
  background: white;
  padding: 10px;
  border-radius:4px;
}

.row{
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.copyrights {
  background-color: var(--third-color) !important;
  color: var(--inverse-text-color);
  padding-top: 15px;
  padding-bottom: 3px;
}
.copyrights p{
  text-align: center;
}

.pylon-phrase{
  margin-top: 36px !important;
}

.pylon-phrase p {
  text-align: center;
}

 @media (max-width: 1090px){
  .pylon-phrase{
  padding:0px 20px;
  margin-top: 0px !important;
}
}
 @media (max-width: 768px) {
  #footer .info-div{
    padding:16px 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .logo-col{
    text-align: start !important;
  }

 }

.footer-back-img{
  width:100%;
}

</style>
