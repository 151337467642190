import { render, staticRenderFns } from "./PaymentReciept.vue?vue&type=template&id=8c65d4f6&scoped=true&"
import script from "./PaymentReciept.vue?vue&type=script&lang=js&"
export * from "./PaymentReciept.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c65d4f6",
  null
  
)

export default component.exports